import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SupportModal } from 'components/Modal/supportModal';
import { SIconButtonMail } from 'components/IconButton';

export const SupportContainer = ({ supportMail }) => (
    <Fragment>
        <SupportModal onCancel={supportMail.toggle} isOpen={supportMail.isOpened} />
        <SIconButtonMail onClick={supportMail.toggle} path="ic-mail" />
    </Fragment>
);

SupportContainer.propTypes = {
    supportMail: PropTypes.object.isRequired,
};
