import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { Input } from "components/Inputs/InputText";
import { Label } from "components/Label";
import { sendSupportMail } from "actions/users";

import { serverVersion } from "actions/user";

const SText = styled.p`
  font-size: 22px;
  margin: 0 0 32px;
`;

const SLabel = styled(Label)`
  display: block;
  margin: 0 0 15px 0;

  & span {
    display: block;
    text-align: center;
    margin: 0 0 5px;
  }
`;

const STextArea = styled.textarea`
  height: 128px;
  padding: 3px 16px;
  border: 1px solid ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  border-radius: 24px;
  font-size: ${STYLES_CONSTANTS.FONTSIZE.default};
  line-height: 18px;
  width: 100%;
  resize: none;

  &:disabled {
    background-color: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  }
`;

const SButtonsBar = styled.div`
  margin-top: 40px;
`;

class _SupportModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    postMail: PropTypes.func.isRequired,
    myProfile: PropTypes.object.isRequired,
  };

  state = {
    title: "",
    description: "",
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const { title, description } = this.state;
      const { postMail, onCancel, myProfile } = this.props;
      const { name, cbreContacts, email } = myProfile;

      await postMail({
        name,
        email: email[0].email,
        message: description,
        title,
        cbreContactsEmails: cbreContacts,
      });

      onCancel();
      this.setState({
        title: "",
        description: "",
      });
      toast.success("Email has been sent successfully");
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };
  async componentDidMount() {
    try {
      await this.props.serverVersion();
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    const { isOpen, onCancel } = this.props;
    const { title, description } = this.state;
    const isDisabled = !(title && description);

    return (
      <Modal isOpen={isOpen} close={onCancel}>
        <SText>Write to us</SText>
        <form onSubmit={this.handleSubmit}>
          <SLabel htmlFor="title">
            <span>Title</span>
            <Input
              id="title"
              name="title"
              onChange={this.handleInputChange}
              value={title}
              required
            />
          </SLabel>
          <SLabel htmlFor="description">
            <span>Description</span>
            <STextArea
              id="description"
              name="description"
              onChange={this.handleInputChange}
              value={description}
              required
            />
          </SLabel>
          <SButtonsBar>
            <Button
              color={STYLES_CONSTANTS.COLORS.WHITE}
              bgColor={STYLES_CONSTANTS.COLORS.DARK_GREEN_2}
              type="submit"
              disabled={isDisabled}
            >
              Send
            </Button>
            <Button
              color={STYLES_CONSTANTS.COLORS.DARK_GREEN_2}
              bgColor="none"
              border
              onClick={onCancel}
            >
              Cancel
            </Button>
          </SButtonsBar>
          <SLabel htmlFor="version">
            <span style={{ marginTop: 10 }}>V 1.2024.1410</span>{" "}
            <span style={{ marginBottom: 10 }}>
              API V{" "}
              {this.props.serverInfo && "version" in this.props.serverInfo
                ? this.props.serverInfo.version
                : "Cannot load from server"}
            </span>
          </SLabel>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  myProfile: state.users.currentUser,
  serverInfo: state.user.serverStatus,
});

const mapDispatchToProps = (dispatch) => ({
  postMail: (data) => dispatch(sendSupportMail(data)),
  serverVersion: () => dispatch(serverVersion()),
});

export const SupportModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SupportModal);
